<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto px-5" :style="{ maxWidth: '680px', paddingTop: '30px', paddingBottom: '50px' }">
    <v-col cols=12 class="pb-5">
      <ComponentsLayoutHeading1 text="お問合せ"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols=8>
      <span v-text="'お名前'"></span>
    </v-col>
    <v-col cols=4 class="text-right" >
      <ComponentsItemsRequireLabel></ComponentsItemsRequireLabel>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsTextField
        name="contact_name"
        :value.sync="contents.name"
        :loading="isProcessLoading"
        ref="name"
      >
      </ComponentsItemsTextField>
    </v-col>
    <v-col cols=12>
      <span v-text="'お名前(カナ)'"></span>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsTextField
        name="contact_kana"
        :value.sync="contents.kana"
        :loading="isProcessLoading"
        ref="kana"
      >
      </ComponentsItemsTextField>
    </v-col>

    <v-col cols=8>
      <span v-text="'郵便番号'"></span>
    </v-col>
    <v-col cols=4 class="pt-5 text-right" >
      <ComponentsItemsRequireLabel></ComponentsItemsRequireLabel>
    </v-col>
    <v-col cols=12>
      <v-row align="center" justify="center">
        <v-col cols=6>
          <ComponentsItemsTextField
            name="post_no"
            :value.sync="contents.post_no"
            :loading="isProcessLoading"
            ref="post_no"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col cols=6 class="text-left">
          <ComponentsItemsButton
            small
            text="検索"
            :loading="isProcessLoading"
            @click="SetAddress"
          ></ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12>
      <span v-text="'都道府県'"></span>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsSelectField
        name='prefecture_id'
        :value.sync="contents.prefecture_id"
        :items="StoragePrefecture"
        itemText="name"
        itemValue="code"
        :loading="isProcessLoading"
        ref="prefecture_id"
      ></ComponentsItemsSelectField>
    </v-col>

    <v-col cols=8>
      <span v-text="'住所'"></span>
    </v-col>
    <v-col cols=4 class="pt-5 text-right" >
      <ComponentsItemsRequireLabel></ComponentsItemsRequireLabel>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsTextField
        name='address'
        :value.sync="contents.address"
        :loading="isProcessLoading"
        ref="address"
      ></ComponentsItemsTextField>
    </v-col>

    <v-col cols=8 class="pt-5">
      <span v-text="'電話番号'"></span>
    </v-col>
    <v-col cols=4 class="pt-5 text-right" >
      <ComponentsItemsRequireLabel></ComponentsItemsRequireLabel>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsTextField
        name="tel"
        :value.sync="contents.tel"
        :loading="isProcessLoading"
        ref="tel"
      >
      </ComponentsItemsTextField>
    </v-col>
    <v-col cols=8 class="pt-5">
      <span v-text="'メールアドレス'"></span>
    </v-col>
    <v-col cols=4 class="pt-5 text-right" >
      <ComponentsItemsRequireLabel></ComponentsItemsRequireLabel>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsTextField
        name="mailAddress"
        :value.sync="contents.mail_address"
        :loading="isProcessLoading"
        ref="mail_address"
      >
      </ComponentsItemsTextField>
    </v-col>
    <v-col cols=8 class="pt-5">
      <span v-text="'お問合せ内容'"></span>
    </v-col>
    <v-col cols=4 class="pt-5 text-right" >
      <ComponentsItemsRequireLabel></ComponentsItemsRequireLabel>
    </v-col>
    <v-col cols=12>
      <ComponentsItemsTextArea
        name="contacts"
        :value.sync="contents.contacts"
        :loading="isProcessLoading"
        ref="contacts"
      >
      </ComponentsItemsTextArea>
    </v-col>
    <v-col cols=12 v-if="isSignIn">
      <span style="white-space:pre-wrap; word-wrap:break-word;" v-text="'画像を3点まで添付いただけます。\n画像ファイル添付（最大5MB）'"></span>
    </v-col>
    <v-col cols=12 v-if="isSignIn">
      <v-col cols=12>
        <template>
          <div id="app">
            <ComponentsItemsContactImageUpload
              name='picture_file'
              v-model="contents.picture_file"
              :value.sync="contents.picture_file"
              ref="picture_file"
            />
          </div>
        </template>
      </v-col>
    </v-col>
    <v-col cols="12" class="text-center pt-5">
      <ComponentsItemsButton
        block
        text="送信する"
        @click="Confirm()"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
import SearchPostNo from 'yubinbango-core2'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextArea from '@/components/items/TextArea'
import ComponentsItemsSelectField from '@/components/items/SelectField'
import ComponentsItemsButton from '@/components/items/Button'
import ComponentsItemsRequireLabel from '@/components/items/RequireLabel'
import ComponentsItemsContactImageUpload from '@/components/items/ContactImageUpload'

export default {
  name: 'ContactPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    contents: {
      name: '',
      kana: '',
      post_no: '',
      prefecture_id: '',
      address: '',
      tel: '',
      mail_address: '',
      contacts: '',
      picture_file: ''
    }
  }),

  computed: {
    Users: {
      get () {
        return this.$store.getters['modStorage/Users']
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        if (this.isSignIn) {
          this.contents.name = this.Users.dealings.dealings_name
          this.contents.kana = this.Users.dealings.dealings_name_kana
          this.contents.post_no = this.Users.dealings.post_no
          this.contents.prefecture_id = this.Users.dealings.prefecture_id
          this.contents.address = this.Users.dealings.address
          this.contents.tel = this.Users.dealings.person_tel
          this.contents.mail_address = this.Users.dealings.person_mail_address
        }
        this.isProcessLoading = false
      })().catch((e) => {
        console.log(e)
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    SetAddress: function () {
      if (this.isUndefined(this.contents.post_no)) {
        return false
      }
      return new SearchPostNo.Core(this.contents.post_no, (data) => {
        const index = this.StoragePrefecture.findIndex(row => row.name === data.region)
        this.contents.prefecture_id = (index >= 0) ? this.StoragePrefecture[index].code : 0
        this.contents.address = data.locality + data.street
      })
    },

    InitContents: function () {
      this.contents.contacts = ''
    },

    Confirm: async function () {
      (async () => {
        const forms = [
          'name',
          'kana',
          'post_no',
          'prefecture_id',
          'address',
          'tel',
          'mail_address',
          'contacts'
        ]
        let error = false
        for (const name of forms) {
          if (this.$refs[name]) {
            await this.$refs[name].confirm()
            if (this.$refs[name].error) {
              error = true
            }
          }
        }
        if (error) {
          return false
        }
        this.$confirm({ title: 'Confirm', message: '入力した内容でお問合せを送信します', button: { yes: '送信する', no: '閉じる' }, callback: this.SendMail })
      })().catch((e) => {
        console.log(e)
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    SendMail: function (ask) {
      (async () => {
        if (ask) {
          this.isProcessLoading = true
          await APILib.putContact(this.contents)
          this.InitContents()
          this.$confirm({ title: 'Success', message: 'お問合せを送信しました', button: { yes: 'OK' } })
          this.isProcessLoading = false
        }
      })().catch((e) => {
        console.log(e)
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutHeading1,
    ComponentsItemsSelectField,
    ComponentsItemsTextField,
    ComponentsItemsTextArea,
    ComponentsItemsButton,
    ComponentsItemsRequireLabel,
    ComponentsItemsContactImageUpload
  }
}
</script>
