
const ValidationLib = {

  rules: function (key) {
    const rules = {
      required: v => !!v || '必須項目です',
      min1: v => v.length >= 1 || '1文字以上にしてください',
      min3: v => v.length >= 3 || '3文字以上にしてください',
      min4: v => v.length >= 4 || '4文字以上にしてください',
      min6: v => v.length >= 6 || '6文字以上にしてください',
      min8: v => v.length >= 8 || '8文字以上にしてください',
      max4: v => v.length <= 4 || '4文字以下にしてください',
      max8: v => v.length <= 8 || '8文字以下にしてください',
      max32: v => v.length <= 32 || '32文字以下にしてください',
      max50: v => v.length <= 50 || '50文字以下にしてください',
      max100: v => v.length <= 100 || '100文字以下にしてください',
      max1000: v => v.length <= 1000 || '1000文字以下にしてください',
      max3000: v => v.length <= 3000 || '3000文字以下にしてください',
      credit_min14: v => v.length >= 17 || '14文字以上にしてください',
      credit_max16: v => v.length <= 19 || '16文字以下にしてください',
      credit_minmax4: v => v.length >= 5 || '4文字以上にしてください',
      regexAccount: v => {
        if (!/[a-zA-Z0-9\\.\\-]/.test(v)) {
          return '半角英数字と一部記号（.-）のみ利用可能です'
        }
        return true
      },
      regexPassword: v => {
        if (!/[a-z]/.test(v)) {
          return '半角の大文字/小文字/数字をそれぞれ1つ以上含めてください。'
        }
        if (!/[A-Z]/.test(v)) {
          return '半角の大文字/小文字/数字をそれぞれ1つ以上含めてください。'
        }
        if (!/[0-9]/.test(v)) {
          return '半角の大文字/小文字/数字をそれぞれ1つ以上含めてください。'
        }
        return true
      },
      emailMatch: v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || '形式が正しくありません',
      numAlpha: v => /^[0-9a-zA-Z.\-_]*$/.test(v) || '半角英数字と一部記号（.-_）のみ利用可能です',
      numeric: v => (v.length === 0 || /^[0-9]+$/.test(v)) || '半角数字のみ利用可能です',
      urlMatch: v => (v.length === 0 || /^https?:\/\//.test(v)) || '形式が正しくありません',
      timeMatch: v => /^([01][0-9]|2[0-3]):[0-5][0-9]$/.test(v) || '形式が正しくありません',
      // kanaMatch: v => /^[ァ-ンヴー]*$/.test(v) || '形式が正しくありません',
      kanaMatch: v => /^[\u{30a1}-\u{30f6}\u{30fc}\u{20}\u{3000}]*$/u.test(v) || '形式が正しくありません'
    }
    const validation = {
      account: [rules.required, rules.min8, rules.max100, rules.emailMatch],
      dealings_name: [rules.required, rules.min1, rules.max32],
      dealings_name_kana: [rules.kanaMatch, rules.min1, rules.max32],
      person_name: [rules.required, rules.min1, rules.max32],
      person_name_kana: [rules.kanaMatch, rules.min1, rules.max32],
      contact_time: [rules.required, rules.min1, rules.max50],
      contact_name: [rules.required, rules.min1, rules.max32],
      name: [rules.required, rules.min1, rules.max32],
      name_kana: [rules.kanaMatch, rules.min1, rules.max32],
      password: [rules.required, rules.min6, rules.max32],
      mailAddress: [rules.required, rules.min8, rules.max100, rules.emailMatch],
      person_mail_address: [rules.required, rules.min8, rules.max100, rules.emailMatch],
      person_mail_address_confirm: [rules.required, rules.min8, rules.max100, rules.emailMatch],
      company_name: [rules.required, rules.min1, rules.max100],
      company_kana: [rules.max100],
      post_no: [rules.required, rules.min1, rules.max8],
      address: [rules.required, rules.min1, rules.max100],
      building: [rules.max100],
      tel: [rules.required, rules.min1, rules.max32],
      fax: [rules.max32],
      url: [rules.max100],
      business_date: [rules.required, rules.min1, rules.max50],
      business_time: [rules.required, rules.min1, rules.max50],
      business_year: [rules.required, rules.min1, rules.max50],
      staff_number: [rules.required, rules.min1, rules.max50],
      domain_name: [rules.required, rules.min1, rules.max32],
      prefecture_id: [rules.required],
      company_id: [],
      shop_name: [rules.required, rules.min1, rules.max32],
      shop_site: [rules.urlMatch, rules.max100],
      shop_description: [rules.max1000],
      str_time: [rules.required, rules.timeMatch],
      end_time: [rules.required, rules.timeMatch],
      introduction: [rules.max1000],
      credit_card_no: [rules.required, rules.credit_min14, rules.credit_max16],
      credit_expire: [rules.required, rules.credit_minmax4, rules.credit_minmax4],
      credit_security_code: [rules.required, rules.min3, rules.max4, rules.numeric],
      credit_holder_name: [rules.required, rules.max50],
      subject: [rules.required, rules.min1, rules.max100],
      contacts: [rules.required, rules.min1, rules.max3000]
    }
    const key1 = (key === 'rePassword') ? 'password' : key
    return (validation[key1]) ? validation[key1] : []
  },

  placeholder: function (key) {
    const placeholder = {
      account: 'メールアドレスを入力',
      dealings_name: '例）株式会社R2',
      dealings_name_kana: '例）カブシキガイシャアールツ－',
      person_name: '例）サンプル 太郎',
      person_name_kana: '例）サンプル タロウ',
      contact_name: '例）サンプル 太郎',
      password: 'パスワードを入力',
      name: '例）ペトコネ 太郎',
      name_kana: '例）ペトコネ タロウ',
      rePassword: '再入力',
      mailAddress: '例）example@r2-pet.com',
      person_mail_address: '例）example@r2-pet.com',
      person_mail_address_confirm: '例）example@r2-pet.com',
      company_name: '例）株式会社カラーズ',
      company_kana: '例）カブシキガイシャカラーズ',
      post_no: '例）6570036',
      address: '例）神戸市灘区桜口町4-1-1',
      building: '例）ウェルブ六甲道4番街1番館402',
      tel: '例）0788581283',
      fax: '例）0788581283',
      url: '例）https://r2-pet.com/',
      domain_name: '例）GREEN DOG',
      prefecture_id: '選択してください',
      company_id: '選択してください',
      shop_name: '例）GREEN DOG SQUARE',
      shop_site: '例）https://www.green-dog.com/store/gds/',
      shop_description: '例）天井高く明るいアットホームな店内には、いつも笑顔があふれています。オーナーさまとパートナーが共に健康で幸せな生活を送れるように・・・　そんな願いを込めて、【GREEN DOG SQUARE】では商品、サービスをご提供しております。お店の奥には、フリースペースがあり、憩いのひと時をお過ごしいただけます。',
      str_time: '10:00',
      end_time: '19:00',
      introduction: '例）トリミングOK、トリミングNG',
      rate: '000.00',
      rates_group_name: '掛け率グループ名',
      credit_card_no: '0000-0000-0000-0000',
      credit_expire: 'YY/MM',
      credit_security_code: '',
      credit_holder_name: '',
      verify_code: 'ここに認証番号を入力',
      original_products_set: '商品を選択してください'
    }
    return (placeholder[key]) ? placeholder[key] : ''
  }

}

export default ValidationLib
