<template>
  <div>
    <label class="upload-content-space user-photo default upload-btn">
     <input ref="file" class="file-button" type="file" @change="upload" @click="imageCheck"/>
     アップロード
   </label>
       <div v-if="value" class="uploaded">
         <label class="upload-content-space user-photo">
           <input ref="file" class="file-button" type="file" @change="upload" />
         </label>
       </div>
    <div cols=8 class="file-list-wrap">
      <ul v-if="fileErrorMessages.length > 0" class="error-messages">
        <li v-for="(message, index) in fileErrorMessages" :key="index">
          {{ message }}
        </li>
      </ul>
      <ul v-if="pictureFileList.length > 0" class="file-list">
        <li v-for="(fileName, index) in pictureFileList" :key="index" class="file-box">
          {{ fileName }}
          <button type="button" :class="'delete-button delete-button[' + index + ']'" @click="deleteImage">
            削除する
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    value: {
      // type: Array,
      default: null
    }
  },
  data () {
    return {
      file: null,
      picture_file: null,
      fileErrorMessages: [],
      pictureFileList: [],
      pictureFileDataList: []
    }
  },
  methods: {
    async imageCheck (event) {
      if (this.pictureFileDataList.length > 2) {
        event.preventDefault()
        this.$confirm({ title: '', message: '画像ファイルの添付上限は3枚までとなります', button: { yes: 'OK' } })
      }
    },
    async upload (event) {
      const files = event.target.files || event.dataTransfer.files
      const file = files[0]
      if (this.checkFile(file)) {
        const picture = await this.getBase64(file)
        const pictureName = file.name
        const pictureFile = {
          filename: pictureName,
          image: picture
        }
        this.pictureFileList.push(pictureName)
        this.pictureFileDataList.push(pictureFile)
        this.$emit('input', this.pictureFileDataList)
      }
    },
    deleteImage (event) {
      const className = event.target._prevClass
      var pictureFileListIndex = className.replace(/[^0-9]/g, '')
      this.pictureFileList.splice(pictureFileListIndex, 1)
      this.pictureFileDataList.splice(pictureFileListIndex, 1)
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    checkFile (file) {
      let result = true
      this.fileErrorMessages = []
      const SIZE_LIMIT = 5242880 // 5MB

      // キャンセルしたら処理中断
      if (!file) {
        result = false
      }
      // jpeg か png 関連ファイル以外は受付けない
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.fileErrorMessages.push('jpeg画像ファイルとpng画像ファイル以外のアップロードはできません。')
        result = false
      }
      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        this.fileErrorMessages.push('アップロードできるファイルサイズは5MBまでです。')
        result = false
      }
      // 3枚まで
      if (this.pictureFileList.length > 2) {
        this.fileErrorMessages.push('アップロードできるファイルは3枚までです。')
        result = false
      }
      return result
    }
  }
}

</script>

<style scoped>
  .user-photo {
    cursor: pointer;
    outline: none;
  }

  .user-photo.default {
    align-items: center;
    background-color: #0074fb;
    border: 1px solid #0051b0;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-flex;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 0.3px;
    color: #fff;
    height: 4rem;
    padding: 0 1.6rem;
    max-width: 177px;
  }

  .user-photo.default:hover {
    background-color: #4c9dfc;
  }

  .user-photo.default:active {
    background-color: #0051b0;
  }

  .user-photo-image {
    max-width: 85px;
    display: block;
  }

  .user-photo-image:hover {
    opacity: 0.8;
  }

  .file-button {
    display: none;
  }

  .uploaded {
    align-items: center;
    display: flex;
  }

  .delete-button {
    background-color: #fff;
    border: none;
    color: #0074fb;
    margin-left: 2rem;
    padding: 0;
  }
  .delete-button:hover {
    text-decoration: underline;
  }
  .error-messages {
    color: #cf0000;
    list-style: none;
    margin: 0.4rem 0;
    padding: 0 0.2rem;
  }

  .user-photo {
    cursor: pointer;
    outline: none;
  }

  .user-photo.default {
    align-items: center;
    background-color: #0074fb;
    border: 1px solid #0051b0;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-flex;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 0.3px;
    color: #fff;
    height: 2rem;
    padding: 0 1.6rem;
    max-width: 177px;
  }

  .user-photo.default:hover {
    background-color: #4c9dfc;
  }

  .user-photo.default:active {
    background-color: #0051b0;
  }

  .user-photo-image {
    max-width: 85px;
    display: block;
  }

  .user-photo-image:hover {
    opacity: 0.8;
  }

  .file-button {
    display: none;
  }

  .upload-btn {
    background-color: #a1a1a1 !important;
    border: none !important;
    padding-top: 0 !important;
  }

  .uploaded {
    align-items: center;
    display: flex;
  }

  .file-list-wrap {
    padding: 10px 0;
  }
  .file-box {
    list-style: none;
    padding: 5px 10px;
    border-bottom: 1px solid #a1a1a1;
  }
  .file-box:first-child {
    border-top: 1px solid #a1a1a1;
  }
  .file-list {
    padding: 0 !important;
  }

</style>
