<template>
<v-carousel cycle continuous hide-delimiter-background show-arrows-on-hover height="auto">
  <v-carousel-item
    v-for="banner in banners"
    :key="banner.src"
    @click="pageTransition(banner.page)"
  >
    <!-- <v-img contain :src="banner.src" class="grey lighten-2 mx-auto" :width="bannerWidthSize" :height="bannerHeightSize" v-bind="attrs" v-on="on"> --->
    <v-img contain :src="banner.src" class="grey lighten-2 mx-auto">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <!--
    <ComponentsItemsBanner
      :imgSrc="banner.src"
      :alt="banner.text"
      :route="banner.page"
      width="960"
      height="407"
      contain
    >
    </ComponentsItemsBanner>
    --->
  </v-carousel-item>
</v-carousel>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

// import ComponentsItemsBanner from '@/components/items/Banner'

export default {
  name: 'ComponentsViewFormsMainBanner',

  mixins: [MixinLib],

  data: () => ({
    banners: [
      {
        page: '/productsList?brand=8cfa4280-cdfc-4ea7-887b-76d47cc38b4c',
        src: 'https://storage.r2-pet.com/images/banner/yum-r2-banner.jpg',
        text: 'こだわりの素材、香るおいしさyum yum yum'
      },
      // {
      //   page: '/productsList?brand=8cfa4280-cdfc-4ea7-887b-76d47cc38b4c',
      //   src: 'https://storage.r2-pet.com/images/banner/R2_banner_1200x500px.jpg',
      //   text: 'こだわりの素材、香るおいしさyum yum yum'
      // },
      {
        page: '/productsList?brand=c70346ee-6db6-4893-8ed4-a87153c9ffdc',
        src: 'https://storage.r2-pet.com/images/banner/kinari2-r2-banner.jpg',
        text: 'きなりは、愛犬にも飼い主さまにもおやつでしあわせな時間を過ごしてほしい、そんな願いを込めてつくった国産の愛犬用おやつです。素材のおいしさそのまんま。国産素材をたっぷり使ったジューシーな味わいを、国内工場からお届けします。'
      },
      {
        page: '/productsList?brand=514a6a40-f0f7-4f0a-927d-71d7bffe2f00',
        // src: 'https://storage.r2-pet.com/images/banner/herrmann-r2-banner.jpg',
        src: require('@/assets/banner/bioliob-r2-banner.jpg'),
        text: '完全無添加・オーガニックドッグフードのHerrmann’s(ヘルマン)。ドイツの犬猫たちが夢中になって食べるごちそうを、日本にも。'
      },
      {
        page: '/productsList?brand=0313f1bd-e120-42bf-ad7b-10044179387e',
        src: 'https://storage.r2-pet.com/images/banner/lila-r2-banner.jpg',
        text: 'LILA LOVES IT（リララブズイット）はドイツ・バイエルン州で生まれたパートナー（愛 犬）の皮膚・被毛を第一に考え、高品質にこだわったシャンプー、リンスなどのトータルボディケア製品です。'
      }
    ]
  }),
  methods: {
  },

  components: {
    // ComponentsItemsBanner
  }
}
</script>
